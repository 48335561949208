import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, Grid, CircularProgress, MenuItem, Select, FormControl, 
  InputLabel, Card, CardContent, Container, IconButton, Typography, 
  Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import GridComponent from './UpdatableGrid';
import { toast, ToastContainer } from 'react-toastify';
import { AttachFile, Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import PopupFormComponent from './EvaluationForm';
import { API_URL } from './constants';
import TermsDialog from './TermsandCondition';
import Joyride from "react-joyride";
import { ChevronDownIcon, PaperclipIcon, XIcon, ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';

const Home = ({user}) => {
  const [prompt, setPrompt] = useState('');
  const [selectedOption, setSelectedOption] = useState('classification');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [showGrid, setShowGrid] = useState(false);
  const [number, setNumber] = useState(100);
  const [loadMoreNumber, setLoadMoreNumber] = useState(0);
  const [showSpinner, setshowSpinner] = useState(false);
  const [columnNames, setColumnNames] = useState('');
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [receivedCount, setReceivedCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  // To animate to the grid.
  const [animate, setAnimate] = useState(false);
  const [showColNames, setShowColNames] = useState(true);
  // const gridRef = useRef(null);

  const [showResults, setShowResults] = useState(false);
  // const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const containerRef = useRef(null);
  const [loadedNumUntilNow, setloadedNumUntilNow] = useState(number);
  const [totalRequiredNumber, setTotalRequiredNumber] = useState(0);

  const [run, setRun] = useState(true);

  const steps = [
    {
      target: ".main-control-1", // class of the element to highlight
      content: "This is the main control for feature X.",
    },
    {
      target: ".main-control-2",
      content: "Click here to start using feature Y.",
    },
    // Add more steps as needed
  ];

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 50 * 1024) {
        toast.error('File size exceeds 50 KB limit. Please contact us for more details.');
        return;
      }
      setFileName(file.name);
      setUploadedFile(file);
      setLoading(false);
      toast.info("This file will not be stored beyond the current data request. Please contact the support team for more details.")
    }
    try {
      const headers = await readCSVHeader(file);
      setColumnNames(headers.join(', '));
    } catch (error) {
      console.error('Error reading CSV header:', error);
      toast.error('Failed to read column names from the CSV file.');
    }
   };

   const handleRemoveFile = () => {
    setFileName('');
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateInputs = () => {
    if (prompt.trim().length === 0 || prompt.split(' ').length < 3) {
      toast.error('Please enter a description with at least 3 words.');
      return false;
    }

    if (selectedOption.trim().length === 0) {
      toast.error('Please select a task.');
      return false;
    }

    if (!isNaN(number) && Number(number) > 500) {
      toast.error('We support only 500 initial data points for one data generation. Please contact the sales team for more details.');
      return false;
    }

    if (isNaN(number) || Number(number) < 0) {
      toast.error('Invalid Number of Records! Please enter a valid number.');
      return false;
    }

    return true;
  };

  const convertJsonStringToArray = (jsonString) => {
    // Use a regular expression to match JSON-like objects, including those with newlines
    const jsonRegex = /{(?:[^{}]|(?:\{[^{}]*\}))*}/g;
    const jsonObjects = jsonString.match(jsonRegex);

    if(jsonObjects === null) return [];
  
    // Parse each JSON object and create an array
    return jsonObjects.map(json => {
      try {
        // Replace escaped newlines with actual newlines
        const cleanedJson = json.replace(/\\n/g, '');
        return JSON.parse(cleanedJson);
      } catch (error) {
        console.error('Error parsing JSON object:', json);
        console.error('Error details:', error);
        return null; // Return null for objects that couldn't be parsed
      }
    }).filter(obj => obj !== null); // Remove any null entries
  };

  const formatColumnNames = (colNames) => {
    return colNames.split(',').map(col => col.trim()).join(', ');
  };
   
  const fetchRowData = async (isLoadMoreClicked) => {
    if (!validateInputs()) {
      return;
    }

    setLoading(true);
    setshowSpinner(true);
    setLoadMoreNumber(0);

    if(!isLoadMoreClicked) {
      setRowData([]);
      setIsDisabled(true);
      setReceivedCount(0);
      setTotalRequiredNumber(Number(number));
    }
    else{
      console.log("Updating Records Number:")
      setLoadMoreNumber(100);
      setTotalRequiredNumber(prevTotal => prevTotal + 100);
    }

    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('usecase', selectedOption);
      if(isLoadMoreClicked){
        formData.append('number', 100);
      }
      else{
        formData.append('number', number);
      }
      formData.append('language', selectedLanguage);
      formData.append('user_id', user.uid);
      formData.append('include_search_results', isChecked);

      if (uploadedFile) {
        formData.append('file', uploadedFile);
      }

        // Convert dynamic column names into colDefs
    const generateColDefs = (columnNames) => {
      return columnNames.split(', ').map(column => ({
        field: column, editable: true, resizable: true, sortable: true, wrapText: true, autoHeight: true, flex: 2
      }));
    };

    if(columnNames != "") {
      const formattedColNames = formatColumnNames(columnNames);
      const colDefs = generateColDefs(formattedColNames)
      setColDefs(colDefs);
      formData.append('column_names', formattedColNames);
    }
    else
    {
      if (selectedOption === 'classification') {
        // "degree, major, GPA, subjects, work experience, preferred locations, recommended courses"
          formData.append('column_names', "Text, Label");
          setColDefs([
            {
              field: "Text", editable: true, resizable: true, sortable: true, 
              wrapText: true, 
              autoHeight: true, 
              flex: 4
            },
            { field: "Label", editable: true, flex: 1}
          ]);
        }
      else if (selectedOption === 'generation') {
        setColDefs([{ field: "Text", editable: true, flex: 5 }]);
      }
      else if (selectedOption === 'qanda') {
        formData.append('column_names', "Question, Answer");
        setColDefs([
          {
            field: "Question", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Answer", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 3
          },
        ]);
      }
      else if(selectedOption === 'insttuning'){
        formData.append('column_names', "Instruction, Input, Output");
        setColDefs([
          {
            field: "Instruction", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Input", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Output", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 3
          }]
        )
      }
    }
      
      const startTime = performance.now();
      const response = await fetch(`${API_URL}/api/generate-data`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(true);
      // const results = await response.json();
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsDisabled(false);
          setshowSpinner(false);
          toast.info("Data Loading has been completed. You can now download the data as a CSV file.")
          break
        }
        const chunk = decoder.decode(value);
        const items = JSON.parse(JSON.stringify(chunk));
        console.log("Items")
        console.log(items);

          const items_converted = convertJsonStringToArray(items);
          if("message" in items_converted[0]){
            toast.error(items_converted[0].message);
            break
          }
          setShowGrid(true);
          setLoading(false);
          // prevData.length >= Number(number) ? prevData : 
          setRowData(prevData => [...prevData, ...items_converted]);
          // prevCount + items_converted.length >= Number(number) ? number :
          setReceivedCount(prevCount =>  prevCount + items_converted.length);
      }

      const endTime = performance.now();
      console.log('It took ' + (endTime - startTime) + ' ms.');

    } catch (error) {
      console.error('Error fetching row data:', error);
      toast.error("An Error occured! Please try again or contact the support team.")
    }
    finally {
        if(!isLoadMoreClicked){
          setLoading(false);
          setShowGrid(true);
          setIsDisabled(false);
          setshowSpinner(false);
        }
    }
  };

  const handleDropdownChange = (event) => {
    var userSelection = event.target.value
    if(userSelection === "classification"){
      setShowColNames(true);
    }
    else{
      setShowColNames(false);
    }
    setColumnNames("");
    setSelectedOption(userSelection);
    setRowData([]);
    setColDefs([]);
    setShowGrid(false);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      setShowResults(true);
      fetchRowData(false);
      setTimeout(() => {
        containerRef.current?.scrollTo({ 
          left: containerRef.current.scrollWidth, 
          behavior: 'smooth'
        });
      }, 500);
      }
  };

  const prepareCSVContent = () => {
    const escapeCSV = (cell) => {
      if (cell == null) return '';
      cell = cell.toString();
      if (cell.includes('"') || cell.includes(',') || cell.includes('\n')) {
        return `"${cell.replace(/"/g, '""')}"`;
      }
      return cell;
    };

    const csvHeader = colDefs.map(col => escapeCSV(col.field)).join(',') + '\n';
    const csvData = rowData.map(row => 
      colDefs.map(col => escapeCSV(row[col.field])).join(',')
    ).join('\n');

    const BOM = '\uFEFF';
    const csvContent = BOM + csvHeader + csvData;
    return csvContent;
  };

  const readCSVHeader = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        const firstLine = content.split('\n')[0];
        const headers = firstLine.split(',').map(header => header.trim());
        resolve(headers);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const downloadCSV = () => {
    const csvContent = prepareCSVContent();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
    setDialogOpen(false);
    // event.preventDefault();
  };

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDownloadClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmitEvaluation = async(evaluation_instructions) => {
    const formData = new FormData();
    const csvContent = prepareCSVContent();
    formData.append('csvData', csvContent);
    formData.append('userId', user.uid);
    formData.append('evalComments', evaluation_instructions)
  
    try {
      toast.success("File Sent for Evaluation. We will get back to you as soon as your dataset is ready.");
      const response = await fetch(`${API_URL}/api/evaluate-csv`, {
        method: 'POST',
        body: formData
      });
      // const decoder = new TextDecoder();
      // const reader = response.body.getReader();
      // const { value } = await reader.read();
      // const chunk = decoder.decode(value);
      // const items = JSON.parse(JSON.stringify(chunk));
    } catch (error) {
      toast.error('Error sending CSV to backend:', error);
    }
  };

  const handleBackToForm = () => {
    setShowResults(false);
    
    // Slide back to the form
    setTimeout(() => {
      containerRef.current?.scrollTo({ 
        left: 0, 
        behavior: 'smooth' 
      });
    }, 100);
  };

  const handleSlide = () => {
      setShowResults(true);
      setTimeout(() => {
        containerRef.current?.scrollTo({ 
          left: containerRef.current.scrollWidth, 
          behavior: 'smooth'
        });
      }, 100);
  };

  const handleLoadMore = () => {
    // setIsLoadMoreClicked(true);
    // setShowResults(true);
    setloadedNumUntilNow(loadedNumUntilNow + loadMoreNumber);
    fetchRowData(true);
  };
  
  return (
    // <div>
    //   <Joyride
    //     steps={steps}
    //     run={run}
    //     continuous={true}
    //     showProgress={true}
    //     showSkipButton={true}
    //     disableBeacon={true}
    //   />
    <div ref={containerRef} className="flex overflow-x-hidden w-screen">
      <div className="flex-shrink-0 w-screen">
        <div className="container mx-auto p-4 max-w-4xl">
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            {/* <button
                  onClick={handleSlide}
                  className="float-right bg-gray-200 text-gray-700 px-4 py-2 rounded-lg mr-2 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  <ArrowRightIcon className="w-5 h-5" />
            </button> */}
          <div className="mb-6">
          <label htmlFor="prompt" className="block text-sm font-medium text-gray-700 mb-2 p-2">
            Describe the data you want to generate in detail in English*
          </label>
          
          <div className="relative main-control-1">
            <textarea
              id="prompt"
              required
              rows="4"
              className="w-full p-3 pr-10 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            {/* PaperClipIcon positioned inside textarea */}
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <button
                type="button"
                onClick={() => fileInputRef.current.click()}
                className="text-gray-400 hover:text-gray-600"
              >
                <PaperclipIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

        {fileName && (
          <div className="mt-2 text-sm text-green-600 flex items-center">
            <span>File selected: {fileName}</span>
            <button onClick={handleRemoveFile} className="ml-2 text-red-500 hover:text-red-700">
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        )}
        
        <input
          required
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
          accept=".csv"
        />
      </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div>
                <label htmlFor="task" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Task*
                </label>
                <div className="relative">
                  <select
                    id="task"
                    value={selectedOption}
                    onChange={handleDropdownChange}
                    className="w-full p-2 border border-gray-300 rounded-lg appearance-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="classification">Text Classification</option>
                    <option value="qanda">Question Answering</option>
                    <option value="insttuning">Instruction Tuning</option>
                  </select>
                  <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>

              <div>
                <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Language*
                </label>
                <div className="relative">
                  <select
                    id="language"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    className="w-full p-2 border border-gray-300 rounded-lg appearance-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                    <option value="te">Telugu</option>
                  </select>
                  <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>

              <div>
                <label htmlFor="number" className="block text-sm font-medium text-gray-700 mb-1">
                  Min. Number of Results*
                </label>
                <input
                  required
                  type="number"
                  id="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <input
                  type="text"
                  id="columnNames"
                  value={columnNames}
                  // hidden={!showColNames}
                  placeholder='Enter comma separated column names. If not mentioned, the columns will be Text and Label.'
                  onChange={(e) => setColumnNames(e.target.value)}
                  className="w-full mr-2 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
             {/* <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-600"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  disabled
                />
                <span className="ml-2 text-sm text-gray-700">Include web search results</span>
              </label> */}
              <button
                onClick={handleSubmit}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Go
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {showResults && (
        <div className="flex-shrink-0 w-screen">
          <div className="container mx-auto p-4">
            {/* {loading && (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            )} */}

            {(
              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={handleBackToForm}
                    className="mr-4 text-blue-600 hover:text-blue-800 focus:outline-none"
                  >
                    <ArrowLeftIcon className="w-6 h-6" />
                  </button>
                  <span className="text-sm text-gray-600 flex items-center">
                  {/* + Number(loadMoreNumber) */}
                    {receivedCount} of {totalRequiredNumber} rows loaded
                    {showSpinner && (
                      <div className="ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                    )}
                  </span>
                  <div>
                    <button
                      onClick={handleLoadMore}
                      disabled={isDisabled}
                      className="mr-2 px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                     Load 100 More
                    </button>
                    <button
                      onClick={handleButtonClick}
                      disabled={isDisabled}
                      className="mr-2 px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                      Submit For Evaluation
                    </button>
                    <button
                      onClick={handleDownloadClick}
                      disabled={isDisabled}
                      className="px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                      Download CSV
                    </button>
                  </div>
                </div>
                <div>
                  {<GridComponent rowData={rowData} colDefs={colDefs} />}
                </div>
                <PopupFormComponent
                  open={isPopupOpen}
                  onClose={handleClosePopup}
                  onSubmit={handleSubmitEvaluation}
                />
                <TermsDialog open={dialogOpen} handleClose={handleDialogClose} handleAgree={downloadCSV} />
              </div>
            )}
          </div>
        </div>
      )}
      <ToastContainer position="top-center" autoClose={3000} />
      {/* <div className="flex justify-end">
        <button
          onClick={handleSlide}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          <ArrowRightIcon className="w-5 h-5" />
        </button>
      </div> */}
    </div>
   // </div>
  );
};

export default Home;
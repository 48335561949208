import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // For routing
import { auth } from '../firebase-auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const Header = ({user}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profilePic, setProfilePic] = useState(''); // User profile picture URL
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown toggle
  const [username, setUsername] = useState(''); // Example username
  const navigate = useNavigate();
  const location = useLocation();
  
  // If the current path is '/special', don't display the AppBar
  const hideAppBar = location.pathname === '/signin' || location.pathname === '/terms-and-privacy';

  useEffect(() => {
    if(user !== null) {
      setIsLoggedIn(true);
      setProfilePic(user.photoURL);
      setUsername(user.displayName);
    };
  },[user]);

  const handleBlogClick = () => {
    navigate("/blog");
  };

  const handleCompetitionClick = () => {
    navigate("/competition");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      user = null;
      setShowDropdown(false);
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  return (
    !hideAppBar && <header className="flex items-center justify-between px-8 py-4 shadow-md bg-white">
      {/* Clickable Logo */}
      <Link to="/" className="text-3xl font-bold text-blue-700 hover:text-blue-900 transition-colors duration-300">
        DataCreator AI
      </Link>

      {/* Buttons */}
      <div className="flex items-center space-x-4">
        <button onClick={handleBlogClick} className="bg-blue-500 text-white px-5 py-2 rounded-md shadow hover:bg-blue-600 transition duration-300">
          BLOG
        </button>
        <button
          onClick={handleCompetitionClick}
          className="relative bg-white text-blue-500 px-5 py-2 rounded-md shadow hover:bg-white-600 transition duration-300 overflow-hidden"
        >
          SYNTHETICDATAFEST
          <span className="absolute inset-0 border-2 border-transparent rounded-md animate-shine"></span>
        </button>

        {/* <button className="border-2 border-blue-700 text-blue-700 px-5 py-2 rounded-md hover:bg-blue-50 transition duration-300">
          Key Features
        </button> */}

        {/* Conditional rendering for Profile or Sign-In */}
        {isLoggedIn ? (
          <div className="relative">
            {/* User Profile Picture */}
            <img
              src={profilePic || "https://via.placeholder.com/40"} // Placeholder image if no URL
              alt="User Profile"
              referrerPolicy="no-referrer"
              className="w-10 h-10 rounded-full border-2 border-blue-600 cursor-pointer"
              onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown on click
            />

            {/* Dropdown */}
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-50">
                <div className="p-2 text-gray-800">Hello, {username}</div>
                <hr />
                <button
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-100"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <button
            className="bg-blue-500 text-white px-5 py-2 rounded-full shadow hover:bg-blue-600 transition duration-300"
            onClick={() => {
              navigate("/signin");
            }}
          >
            Sign In
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
import React, { useState } from 'react';
import { Upload, Trophy } from 'lucide-react';
import { API_URL } from './constants';
import { toast, ToastContainer } from 'react-toastify';

const CompetitionComponent = ({user}) => {
  const [activeTab, setActiveTab] = useState('upload');
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [description, setDescription] = useState("");

  const [datasetTopic, setDatasetTopic] = useState("");
  const [topicError, setTopicError] = useState("");

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Files submitted:", files);
    console.log("Description:", description);

    const formData = new FormData();
    formData.append("user_id", user.uid);
    if(files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      formData.append('topic', datasetTopic);
      formData.append('comments', description);
      toast.success('Thank you for your submission for SyntheticDataFest. The leaderboard will be updated shortly!');

      try {
        const response = await fetch(`${API_URL}/api/upload-competition-files`, {
          method: 'POST',
          body: formData,
        });
        
        if (!response.ok) {
          toast.error('Some error Occurred. Please try again.');
        }
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
    else{
      toast.error("Please upload atleast one CSV File for submission.")
    }
  };

  const FileUploadTab = () => (
    <div
      className={`w-full max-w-xl p-8 space-y-6 text-center bg-white dark:bg-gray-800 rounded-xl shadow-lg transition-all duration-300 ease-in-out transform ${isDragging ? 'scale-105 border-4 border-dashed border-blue-500' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p className="text-lg text-gray-600 dark:text-gray-300">
        Read More About SyntheticDataFest <a href="/blog/synthetic-data-fest" style={{ textDecoration: 'underline', color: 'inherit' }}>here
        </a>
      </p>
      <Upload className="w-20 h-20 mx-auto text-blue-500 dark:text-blue-400 animate-bounce" />
      <h2 className="text-3xl font-bold text-gray-800 dark:text-white">Upload Your Files</h2>
      <p className="text-lg text-gray-600 dark:text-gray-300">Upload Reviewed Datasets as CSV Files.*</p>
      <label className="relative block group">
        <input 
          type="file" 
          className="sr-only"
          onChange={handleFileChange}
          accept=".csv,.xls,.xlsx,.ipynb,.py,.jpg,.png"
          multiple
        />
        <span className="block w-full px-4 py-3 text-lg font-semibold text-center text-white bg-blue-500 rounded-lg cursor-pointer group-hover:bg-blue-600 transition duration-300 ease-in-out">
          Choose files
        </span>
      </label>
      {files.length > 0 && (
        <div className="mt-4 p-4 bg-green-100 dark:bg-green-800 text-green-700 dark:text-green-200 rounded-lg text-lg animate-fade-in-down">
          {files.length} file(s) selected
        </div>
      )}
      <p className="text-sm text-gray-500 dark:text-gray-400">or drag and drop your files here</p>
      {/* <div className="mt-6">
        <textarea
          className="w-full p-3 text-lg border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition-colors duration-300 ease-in-out dark:bg-gray-700 dark:text-white dark:border-gray-600"
          rows="1"
          placeholder="Please enter the topic of your dataset.*"
          value={datasetTopic}
          onChange={(e) => setDatasetTopic(e.target.value)}
        ></textarea>
      </div>
      <div className="mt-6">
        <textarea
          className="w-full p-3 text-lg border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition-colors duration-300 ease-in-out dark:bg-gray-700 dark:text-white dark:border-gray-600"
          rows="4"
          placeholder="Summarize the changes you have done in the file briefly. Add any other comments or feedback before submission."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div> */}

      <button
        className="mt-4 w-full px-4 py-3 text-lg font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out"
        onClick={handleSubmit}
      >
        Submit
      </button>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );

  const LeaderboardTab = () => {
    const leaderboardData = [
      { rank: 1, name: "JKL", points: 1200, topic: "Climate Change" },
      { rank: 2, name: "ABC", points: 1100, topic: "Renewable Energy" },
      { rank: 3, name: "DEF", points: 1000, topic: "Ocean Conservation" },
      { rank: 4, name: "XYZ", points: 900, topic: "Sustainable Agriculture" },
      { rank: 5, name: "PQR", points: 800, topic: "Biodiversity" },
    ];

    return (
      <div className="w-full max-w-4xl p-8 space-y-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-x-auto">
        <h2 className="text-3xl font-bold text-gray-800 dark:text-white text-center mb-6">Leaderboard</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-700">
              <th className="p-3 text-left font-semibold text-gray-700 dark:text-gray-300">Rank</th>
              <th className="p-3 text-left font-semibold text-gray-700 dark:text-gray-300">Name</th>
              <th className="p-3 text-left font-semibold text-gray-700 dark:text-gray-300">Points</th>
              <th className="p-3 text-left font-semibold text-gray-700 dark:text-gray-300">Dataset Topic</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((user, index) => (
              <tr key={index} className="border-b border-gray-200 dark:border-gray-700">
                <td className="p-3 text-gray-800 dark:text-gray-300">{user.rank}</td>
                <td className="p-3">
                  <div className="flex items-center space-x-3">
                    <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold">
                      {user.name[0]}
                    </div>
                    <span className="text-gray-800 dark:text-gray-300">{user.name}</span>
                  </div>
                </td>
                <td className="p-3 font-semibold text-blue-500">{user.points}</td>
                <td className="p-3 text-gray-600 dark:text-gray-400">{user.topic}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900 p-4">
      <div className="mb-6 flex space-x-4">
        <button
          className={`px-4 py-2 rounded-lg ${activeTab === 'upload' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'}`}
          onClick={() => setActiveTab('upload')}
        >
          File Upload
        </button>
        <button
          className={`px-4 py-2 rounded-lg ${activeTab === 'leaderboard' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'}`}
          onClick={() => setActiveTab('leaderboard')}
        >
          Sample Leaderboard
        </button>
      </div>
      {activeTab === 'upload' ? <FileUploadTab /> : <LeaderboardTab />}
    </div>
  );
};

export default CompetitionComponent;
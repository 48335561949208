import React from 'react';
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const blogs = [
    {
      id: 1,
      title: 'SyntheticDataFest 2024',
      description: 'October is almost here, and it brings the Hacktoberfest with it. This year, we plan to host our very own version of this event - SyntheticDataFest.',
      image: 'MainImage.jpg',
      slug: '/blog/synthetic-data-fest',
    },
    // {
    //   id: 2,
    //   title: 'What is AI?',
    //   description: 'Explore the future of artificial intelligence and its potential to change the world.',
    //   image: 'WhatIsAI.jpg',
    //   slug: '/blog/what-is-ai',
    // },
    {
      id: 3,
      title: 'Data Quality for Large Language Models',
      description: 'Learn the different quality criteria for datasets used to train and finetune AI models.',
      image: 'DataQuality.jpg',
      slug: '/blog/data-quality-llms',
    },
    // {
    //   id: 4,
    //   title: 'AI & Synthetic Data',
    //   description: 'Discover how synthetic data is revolutionizing the AI landscape.',
    //   image: 'https://via.placeholder.com/300',
    //   slug: '/blog/synthetic-data-ai',
    // }
  ];

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <h1 className="text-4xl font-bold text-center mb-12 text-blue-800 dark:text-white font-roboto">Latest Blog Posts</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog) => (
            <div key={blog.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
              <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-2 font-roboto">{blog.title}</h2>
                <p className="text-gray-600 dark:text-gray-300 mb-4 font-roboto">{blog.description}</p>
                <Link
                  to={blog.slug}
                  className="text-blue-500 hover:text-blue-600 font-semibold transition-colors duration-300"
                >
                  Read More &rarr;
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import syntheticDataImg from './BlogContent/MainImage.jpg'
import dataQuality from './BlogContent/DataQuality.jpg'


const BlogPost = () => {
  const { slug } = useParams();

  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/signin'); // Redirects to the signin page
  };

  // Simulating a blog content fetch
  const blogs = {
    'synthetic-data-fest': {
      title: 'SyntheticDataFest @ DataCreator AI', 
      content : `<div class="prose lg:prose-xl prose-full max-w-none mx-auto px-6">
        <img src=${syntheticDataImg} alt="Synthetic Data Generation" />
        
        <p>October is almost here, and it brings the Hacktoberfest with it. This year, we plan to host 
        our very own version of Hacktoberfest at DataCreator AI. I would like to cordially invite you all to this celebration of technology.</p>

        <h2>What is Hacktoberfest?</h2>
        <p>Every year in the month of October, developers contribute to open-source projects on each day of the month. They raise pull or merge requests to make valuable contributions to open-source projects.</p>
        <p>We have based our event on this very famous and fun event. The saying "Data is the new oil" was a mantra of the past decade. As we enter the era of AI, data becomes 
        even more valuable—it is now the new gold. Just like gold is not really valuable unless it is of 
        high quality and polished, so is data.</p>

        <h2>What is SyntheticDataFest?</h2>
        <p>Hacktoberfest is a time for coding, but at DataCreator AI, we believe data is just as important as code. In the AI era, 
        data is the new gold—but just like gold, it needs to be polished and of high quality to be truly valuable. Our mission is to 
        empower AI professionals to generate high-quality synthetic data combined with their personal expertise, and this event is the perfect way to contribute to that goal.</p>

        <h2>How do you participate?</h2>
        <ul>
            <li>Register anytime on DataCreator AI between September 23rd and the end of October 30, 2024.</li>
            <li>Between October 1st and October 31st, generate a unique dataset for a niche topic. 
            We’ll provide a broad daily theme, and you have to choose a niche topic that falls under the 
            theme. </li>
            <li>Review the data using <a href= "/blog/data-quality-llms">our guidelines</a> and add your unique perspective to it.</li>
            <li>We will review your datasets and publish the best work at the end of each day as long as it meets our <a href='/terms-and-privacy'>Terms of Use</a>.</li>
            <li>The datasets you generate and review in Hindi and Telugu will give you extra 500 points.</li>
            <li>The submissions with visualizations or fine-tuning with the generated datasets will give you extra 500 points.</li>
            <li>Attend the events and tutorials to guide you on your data journey.</li>
        </ul>

        <h2>What's in it for you?</h2>
        <ul>
            <li>Digital Badges to showcase on your social profiles for all participants.</li>
            <li>The best dataset and analysis of each day will be featured on our Community Datasets and our LinkedIn page.</li>
            <li>Earn points and climb our leaderboard—the more data you create, the higher you rank!</li>
            <li>The Top 3 users will get 6 months of free access to all our premium features.</li>
            <li>The Top 10 winners will be invited to join our upcoming paid reviewer system.</li>
            <li>The users with the highest daily streak will be offered a special prize.</li>
        </ul>

        <h2> Eligibility Criteria </h2>
        <ul>
            <li>You must be 18 years or older and have a registered Gmail account to participate.</li>
        </ul>

        <h2>Terms and Conditions</h2>
         <ul>
            <li>Please note that we reserve all the rights to determine the best submission and our decision is final.</li>
            <li>This competition offers no promises of employment or any monetary benefits. </li>
            <li>You understand that the datasets you provide may be available for download by our users. You can remove them anytime. </li>
            <li>Generation of explicit, hateful or Not-Suitable-For-Work(NSFW) content is strictly prohibited. Violation of our Terms of Use will lead to disciplinary action. </li>        
         </ul>

        <p class="italic">On October 31st, we will host a LinkedIn event to announce the winners, discuss standout datasets, and talk about the future of SyntheticDataFest. We are not officially associated with HacktoberFest and this is only an inspiration of the event.</p>
    </div>`
    },
    // 'what-is-ai': {
    //   title: 'What is AI?',
    //   content: `
    //     <p>Artificial Intelligence (AI) is advancing at a rapid pace and is expected to revolutionize multiple industries. From healthcare to transportation, AI applications are expanding.</p>
    //     <p>AI could bring great innovation, but it also poses ethical and societal challenges. Understanding the future of AI is crucial to prepare for its widespread adoption.</p>
    //     <h2>Key Benefits of AI</h2>
    //     <ul>
    //       <li>Automation of repetitive tasks</li>
    //       <li>Enhanced decision-making</li>
    //       <li>Cost savings and efficiency improvements</li>
    //     </ul>
    //   `,
    // },
    'data-quality-llms': {
      title: 'Data Quality for Training Large Language Models',
      content: `
<body>
  <div class="prose lg:prose-xl prose-full max-w-none mx-auto px-6">

    <img src=${dataQuality} alt="Data Quality for AI Model Training" />

    <p>Data is the main component of any Machine Learning (ML) or Artificial Intelligence (AI) system. 
    We all remember the issues that arose when Google's Gemini was trained on Reddit data—resulting in 
    some bizarre AI-generated search responses, such as suggesting glue to make a pizza.</p>

    <p>This highlights that training on any or all data may not always be the best approach. If you ask 
    any AI/ML engineer, they will likely tell you that most of their time is spent on data cleaning, 
    preprocessing, and management. The old adage "garbage in, garbage out" holds especially true for 
    Large Language Models (LLMs), which, like our brains, are influenced by what they are fed. 
    Just as the quality of the input to our brains affects our quality of life, the quality of data fed 
    to LLMs affects their output.</p>

    <p>Initially, the goal was to train LLMs with as much data as possible. However, the focus has since 
    shifted to prioritizing quality data, even if the dataset is smaller. This gave rise to smaller language 
    models that excel at particular tasks. Microsoft's Phi is a great example for this. In this blog, we will
    go deeper into what constitutes a good quality dataset. </p>

    <h2>What Should You Watch Out For?</h2>
    <p>Below are the criteria to ensure that a dataset whether human or AI-generated is of high quality. 
    As a part of our ongoing <a href="/blog/synthetic-data-fest">SyntheticDataFest 2024</a> we rank user 
    uploaded datasets based on these criteria. </p>

    <h3>1. Factually Correct Data</h3>
    <p>LLM hallucinations are a well-known issue. For instance, there was a legal case where a lawyer 
    cited an older court case that did not exist citing a ChatGPT response. While hallucinations may 
    still occur, we need to ensure that the dataset itself isn't introducing false information. 
    Especially since synthetic data is generated by LLMs the likelihood of hallucinations increases. This
    is where human expertise particularly comes into the picture. RAG systems have been able to mitigate
    this problem to a certain extent but human intervention is still needed to verify facts in most
    applications. 
    </p>

    <h3>2. Bias Mitigation</h3>
    <p>Datasets come with various kinds of subtle and obvious biases. I once generated synthetic data from an LLM to train a model to classify employee reviews, 
    using several made-up employee names. The model, unfortunately, associated negative reviews with 
    female names and positive reviews with male names. This kind of bias can lead to flawed 
    classification systems if models are trained on such data.</p>

    <h3>3. Avoiding Toxic Language</h3>
    <p>Beyond biases, datasets may also contain toxic language, such as abuse, hate speech or offensive content. This issue is particularly common with open-source models like LLaMA, which often lack the necessary guardrails to filter out harmful language.</p>

    <h3>4. Completeness</h3>
    <p>When generating synthetic data using LLMs, a maximum token limit is often set. This can lead to 
    incomplete responses if the output is truncated. For example, if you're generating a question-answer
     dataset, the question may be generated, but the answer could get cut off. 
     If the answer is incomplete, it is crucial to either complete it or remove the question if it's 
     not relevant.
     Ensuring completeness guarantees that the synthetic dataset is usable and reliable, especially for critical domains like law, medicine, or finance.
     </p>

    <h3>5. Data Diversity</h3>
    <p>No two people are the same, and everyone perceives the world differently. Similarly, the dataset 
    should reflect diverse perspectives, ensuring a well-rounded dataset. When a dataset has data points
    that are too similar, it could lead to the model overfitting to that specific kind of data reducing
    the overall accuracy. For example, a dataset that contains 1000 movie reviews from different geographic locations, 
     in multiple languages, from different age groups is better than a dataset that contains 10,000 reviews, but 90% of them are from a single city.
    </p>

    <h3>6. Consistency</h3>
    <p>The data should follow a consistent format. For instance, for classification, the class names 
    should remain uniform throughout the dataset to avoid confusion and errors. An education dataset
    might have grades on various scales that need to be normalized. For Question Answering, if some answers have only one word and the others have a detailed answer 
    with multiple paragraphs for the same type of questions, this could confuse the model.</p>

    <h3>7. Grammatical and Spelling Accuracy</h3>
    <p>Modern LLMs excel in generating grammatically and semantically correct sentences in English. However, when it comes to low-resource languages, such as some Indic languages, errors in grammar and spelling are more frequent. It's important to address these mistakes to ensure accurate responses.</p>
  </div>
      `,
    },
//     'synthetic-data-ai': {
//       title: 'How Synthetic Data is Transforming AI',
//       content: `
//         <p>Synthetic data has emerged as a powerful tool in AI development. By generating artificial datasets, companies can overcome the limitations of real-world data.</p>
//         <h2>Benefits of Synthetic Data</h2>
//         <p>Some key advantages include:</p>
//         <ul>
//           <li>Scalability</li>
//           <li>Privacy preservation</li>
//           <li>Bias reduction</li>
//         </ul>
//       `,
//     },
  };

  const blog = blogs[slug];

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <h1 className="text-center text-4xl font-bold text-gray-800 dark:text-white mb-4 font-roboto">{blog.title}</h1>
          <div
            className="prose dark:prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          ></div>
          <div className="mt-12 text-center">
          <button
            onClick={handleGetStartedClick}
            className="bg-blue-500 text-white font-semibold py-3 px-6 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out"
          >
            Get Started
          </button>
      </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
import React, { useState } from 'react';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Link, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const sections = [
    { name: "blog", title: "Blog", isScroll : false },
    { name: "features", title: "Features", isScroll: true },
    { name: "login", title: "Sign up", isScroll: false}
    // { name: "datasets", title: "Community Datasets", isScroll: false},
  ];

  const features = [
    {
      title: "Multiple Task Support",
      description: "Create Synthetic data for any usecase such as Text Classification, Question Answering, Named Entity Recognition, Instruction Fine-Tuning across various domains."
    },
    {
      title: "Real-time Data Previews",
      description: "Get immediate visual feedback with real-time data previews. Instantly see and verify your generated datasets, allowing you to ensure accuracy and relevance before exporting."
    },
    {
      title: "Enhance Datasets",
      description: "Upload your own custom knowledge or data sources and generate data that reflects the style and knowledge of the existing data."
    },
    {
      title: "Multilingual Support",
      description: "Generate synthetic data for NLP applications in multiple non-English languages such as Hindi, Telugu and German. We are adding more languages everyday."
    }
  ];

  const handleNavigation = (sectionName) => {
    if (sectionName === "blog") {
      navigate("/blog");
    } else if (sectionName === "pricing") {
      navigate("/pricing");
    } else if (sectionName === "login") {
      navigate("/signin");
    }
  };

  const handleGetStarted = () => {
    navigate("/signin");
  };

  const handleJoinEvent = () => {
    navigate("/blog/synthetic-data-fest");
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="fixed w-full z-50 bg-white shadow-md">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <RouterLink to="/" style={{ textDecoration: 'none', flexGrow: 1 }}>
                <span className="text-2xl font-bold text-blue-600">DC</span>
              </RouterLink>
              <span className="hidden sm:inline-block ml-2 text-xl font-semibold text-gray-800">DataCreator AI</span>
            </div>
            <nav className="hidden md:flex space-x-4">
              {sections.map((section) => (
                section.isScroll ? (
                  <ScrollLink
                    key={section.name}
                    to={section.name}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="text-blue-600 hover:text-blue-600 transition-colors duration-300 cursor-pointer"
                  >
                    {section.title}
                  </ScrollLink>
                ) : (
                  <button
                    key={section.name}
                    onClick={() => handleNavigation(section.name)}
                    className="text-blue-600 hover:text-blue-600 transition-colors duration-300 cursor-pointer"
                  >
                    {section.title}
                  </button>
                )
              ))}
            </nav>
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              >
                {isMenuOpen ? (
                  <XMarkIcon className="h-6 w-6" />
                ) : (
                  <Bars3Icon className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile menu */}
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: isMenuOpen ? 1 : 0, y: isMenuOpen ? 0 : -100 }}
        transition={{ duration: 0.3 }}
        className={`md:hidden fixed inset-0 z-40 bg-white ${isMenuOpen ? 'block' : 'hidden'}`}
      >
        <div className="pt-20 pb-4 px-4">
        {sections.map((section) => (
          <div key={section.name} className="mb-2">
            {section.isScroll ? (
              <ScrollLink
                to={section.name}
                smooth={true}
                duration={500}
                offset={-70}
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                className="block py-2 text-gray-600 hover:text-blue-600 transition-colors duration-300"
              >
                {section.title}
              </ScrollLink>
            ) : (
              <button
                onClick={() => {
                  handleNavigation(section.name);
                  setIsMenuOpen(false);
                }}
                className="block w-full text-left py-2 text-gray-600 hover:text-blue-600 transition-colors duration-300"
              >
                {section.title}
              </button>
            )}
          </div>
        ))}
        </div>
      </motion.div>

      {/* Hero Section */}
      <Element name="hero" className="pt-24 md:pt-32 pb-16 md:pb-24 bg-gradient-to-br from-blue-50 via-indigo-50 to-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-4xl mx-auto">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl text-blue-600 font-bold mb-4"
            >
              Infinite Data, Limitless Possibilities
            </motion.h2>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-5xl sm:text-6xl md:text-7xl font-extrabold text-blue-600 mb-8 leading-tight"
            >
              Never Run Out of Data.
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="text-xl text-blue-600 mb-12 max-w-3xl mx-auto"
            >
              Create custom synthetic datasets effortlessly with our powerful data generation tool. Perfect for developers, data scientists, and AI businesses.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="flex flex-col sm:flex-row justify-center gap-4"
            >
              <button 
                onClick={handleGetStarted}
                className="bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-700 transition-colors duration-300 shadow-lg"
              >
                GET STARTED FOR FREE
              </button>
              <button 
                onClick={handleJoinEvent}
                className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full border-2 border-blue-600 hover:bg-blue-50 transition-colors duration-300 shadow-lg"
              >
                JOIN SYNTHETICDATAFEST
              </button>
            </motion.div>
          </div>
        </div>
      </Element>

      {/* Features Section */}
      <Element name="features" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-blue-600 mb-12">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
              >
                <h3 className="text-xl font-semibold text-blue-600 mb-4">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </Element>

      {/* CTA Section */}
      <Element name="cta" className="bg-blue-600 py-20">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to create your own dataset?</h2>
            <p className="text-xl text-blue-100 mb-8">Start generating custom synthetic data today.</p>
            <button 
              onClick={handleGetStarted}
              className="bg-white text-blue-600 font-bold py-3 px-8 rounded-full hover:bg-blue-50 transition-colors duration-300 shadow-lg"
            >
              Get Started Now
            </button>
          </div>
        </div>
      </Element>

      {/* Footer */}
      <footer className="bg-gray-100 py-8">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-600 mb-4 md:mb-0">
              © {new Date().getFullYear()} DataCreator AI. All rights reserved.
            </div>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/company/syntheta/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600 transition-colors duration-300">
                <FaLinkedin size={24} />
              </a>
              <a href="https://www.instagram.com/priyankamadiraju/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600 transition-colors duration-300">
                <FaInstagram size={24} />
              </a>
              <a href="https://twitter.com/priyankanew27" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600 transition-colors duration-300">
                <FaTwitter size={24} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;